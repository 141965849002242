<div class="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:p-8">
    <div class="flex md:w-full md:max-w-6xl sm:rounded-2xl sm:shadow overflow-hidden sm:bg-card">
        <div class="relative hidden md:flex flex-auto items-center justify-center h-full p-16 lg:px-28 overflow-hidden bg-gray-800 dark:border-r">
            <!-- Background - @formatter:off -->
            <!-- Rings -->
            <svg class="absolute inset-0 pointer-events-none"
                 viewBox="0 0 960 540" width="100%" height="100%" preserveAspectRatio="xMidYMax slice" xmlns="http://www.w3.org/2000/svg">
                <g class="text-gray-700 opacity-25" fill="none" stroke="currentColor" stroke-width="100">
                    <circle r="234" cx="196" cy="23"></circle>
                    <circle r="234" cx="790" cy="491"></circle>
                </g>
            </svg>
            <!-- Dots -->
            <svg class="absolute -top-16 -right-16 text-gray-700"
                 viewBox="0 0 220 192" width="220" height="192" fill="none">
                <defs>
                    <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                        <rect x="0" y="0" width="4" height="4" fill="currentColor"></rect>
                    </pattern>
                </defs>
                <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"></rect>
            </svg>
            <!-- @formatter:on -->
            <!-- Content -->
            <div class="z-10 relative w-full max-w-2xl">
                <div class="text-7xl font-bold leading-none text-gray-100">
                    <div>Welcome to</div>
                    <div>our community</div>
                </div>
                <div class="mt-6 text-lg font-bold tracking-tight leading-6 text-gray-100">
                    Amicus InnerWorld is the leading host of custom and personalized chatbots for professionals, businesses and organizations (PBOs & CBOs) wishing to engage with social media users at scale.
                </div>
                <div class="mt-6 text-lg tracking-tight leading-6 text-gray-400">

                    <ol>
                            <li>
                                <ul>
                                    <div class="mt-11 mb-5 border-t"></div>
                                    <strong>Example Legal Thematic Areas</strong> <p><strong>FGM</strong> - <strong>Human Rights</strong> - <strong>SGBV</strong> - <strong>Land & Property</strong> - <strong>Succession</strong></p>
                                    <div class="mt-6 mb-10 border-t"></div>
                                </ul>
                            </li>
                        </ol>
                </div>
                <div class="mt-6 text-lg font-bold tracking-tight leading-6 text-gray-100">
                    &#64;amicusSTUDIO we build AI powered social media posts to drive engagement, leads generation and advocacy for professionals and organizations 24/7.
                </div>
                <div class="flex items-center mt-8">
                    <div class="flex flex-0 items-center -space-x-1.5">
                        <img
                            class="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover"
                            src="assets/images/avatars/female-14.jpg">
                        <img
                            class="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover"
                            src="assets/images/avatars/female-09.jpg">
                        <img
                            class="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover"
                            src="assets/images/avatars/male-05.jpg">
                        <img
                            class="flex-0 w-10 h-10 rounded-full ring-4 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover"
                            src="assets/images/avatars/ayub-robert.jpg">
                    </div>
                    <div class="ml-4 font-medium tracking-tight text-gray-400">Join the fastest growing social media advocacy network and be empowered. It's your turn!</div>
                </div>
            </div>
        </div>
        <div class="w-full sm:w-auto py-8 px-4 sm:p-12 md:p-16">
            <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
                <!-- Logo -->
                <div class="flex items-center mt-8">
                    <div class="sm:w-20 items-center">
                        <img src="assets/images/logo/logo.svg">
                    </div>
                    <div class="flex-auto mt-px border-t"></div>
                    <div class="mx-2 text-secondary font-extrabold">Personal, Trusted & Reliable</div>
                    <div class="flex-auto mt-px border-t"></div>
                </div>
                <!-- Alert -->
                <fuse-alert
                    class="mt-8 -mb-4"
                    *ngIf="showAlert"
                    [appearance]="'outline'"
                    [showIcon]="false"
                    [type]="alert.type"
                    [@shake]="alert.type === 'error'">
                    {{alert.message}}
                </fuse-alert>

                <!-- Title -->
                <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight">Congratulations!</div>
                <div class="mt-4" *ngIf="!showAlert">
                    Please wait as your account is being setup
                    <div class="mt-5 mb-5 border-t"></div>
                    <p>
                        <b>100 Shillings</b> worth of research credits is being added to your account. After the success message appears, proceed and click on the <b><u>sign in</u></b> link once it appears below!
                    </p>
                    <div class="mt-5 mb-5 border-t"></div>

                </div>

                <!-- Form footer -->
                <div class="mt-8 text-md font-medium text-secondary" *ngIf="showAlert">
                    <span>Return to</span>
                    <a
                        class="ml-1 text-primary-500 hover:underline"
                        [routerLink]="['/sign-in']">sign in
                    </a>
                </div>

            </div>
        </div>
    </div>
</div>
