import { CurrencyPipe, DecimalPipe, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { MessagesComponent } from 'app/layout/common/messages/messages.component';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';
import { QuickChatComponent } from 'app/layout/common/quick-chat/quick-chat.component';
import { SearchComponent } from 'app/layout/common/search/search.component';
import { ShortcutsComponent } from 'app/layout/common/shortcuts/shortcuts.component';
import { UserComponent } from 'app/layout/common/user/user.component';
import { Subject, takeUntil } from 'rxjs';
import { AmicusChatService } from 'app/modules/admin/apps/chat/amicus.chat.service';

@Component({
    selector     : 'classy-layout',
    templateUrl  : './classy.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone   : true,
    imports      : [FuseLoadingBarComponent, FuseVerticalNavigationComponent, NotificationsComponent, UserComponent, NgIf, MatIconModule, MatButtonModule,
        LanguagesComponent,
        FuseFullscreenComponent, SearchComponent, ShortcutsComponent, MessagesComponent, RouterOutlet, QuickChatComponent, MatIconModule,  NgIf, MatButtonModule,
        DecimalPipe,
        CurrencyPipe],
})
export class ClassyLayoutComponent implements OnInit, OnDestroy
{
    isScreenSmall: boolean;
    navigation: Navigation;
    user: User;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    available_token_balance: number;
    used_token_balance: number;
    loyalty_points_balance: number;
    count_of_chatbot_balance: number;

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _userService: UserService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _amicusChatService: AmicusChatService,
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) =>
            {
                this.navigation = navigation;
            });

        // Subscribe to the user service
        this._userService.user$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((user: User) =>
            {
                this.user = user;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) =>
            {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

        // Get the realtime available token balance
        this._amicusChatService.getAvailableTokensBalanceUpdates().subscribe(
            (balance) => {
              this.available_token_balance = balance;
              console.log("The available token balance is :: "+this.available_token_balance)
            },
            (error) => {
              console.error('Error receiving balance update:', error);
            }
          );

        // Get the realtime used token balance
        this._amicusChatService.getUsedTokensBalanceUpdates().subscribe(
            (balance) => {
              this.used_token_balance = balance;
              console.log("The used token balance is :: "+this.used_token_balance)
            },
            (error) => {
              console.error('Error receiving used token balance update:', error);
            }
          );

        // Get the realtime loyalty points balance
        this._amicusChatService.getLoyaltyPointsBalanceUpdates().subscribe(
            (balance) => {
              this.loyalty_points_balance = balance;
              console.log("The loyalty points balance is :: "+this.loyalty_points_balance)
            },
            (error) => {
              console.error('Error receiving balance update:', error);
            }
          );

        // Get the realtime count of chatbots
        this._amicusChatService.getCountOfChatbotsBalanceUpdates().subscribe(
            (balance) => {
              this.count_of_chatbot_balance = balance;
              console.log("The count of chatbots balance is :: "+this.count_of_chatbot_balance)
            },
            (error) => {
              console.error('Error receiving balance update:', error);
            }
          );

        // Display the user object
        // console.log("The user details is "+JSON.stringify(this.user))
        // {
        //     "id": 2,
        //     "cl_user_id": null,
        //     "research_credits": 0,
        //     "is_subscribed": true,
        //     "is_staff": false,
        //     "is_active": true,
        //     "is_admin": false,
        //     "phone_number": 718758221,
        //     "is_owner": false,
        //     "is_advisor": false,
        //     "is_verified": false,
        //     "first_name": "Robert",
        //     "last_name": "Ayub",
        //     "about": null,
        //     "avatar": null,
        //     "email": "robert@ayub.co.ke",
        //     "customer_rank": 0,
        //     "image": "",
        //     "code_agency": null,
        //     "provider__id": 1,
        //     "provider__provider_name": "University of Nairobi",
        //     "member__id": 1,
        //     "member__member_type_name": "Amicus User",
        //     "date_joined": "2024-10-12T12:43:39.179892Z",
        //     "message_unit_charge": 1,
        //     "message_total_word_count": 100,
        //     "incoming_message_word_count": 20,
        //     "outgoing_message_word_count": 80,
        //     "question_multiplier_to_answer": 700,
        //     "loyalty_points": 0,
        //     "amicus_user_group__id": 1,
        //     "amicus_user_group__group_name": "Front Office Admin",
        //     "amicus_user_group__group_description": "These are administrators with access to Amicus online portal with permissions to do Level 0 administrative support tasks",
        //     "access_chat_application": true,
        //     "access_caselaws_application": false,
        //     "access_firac_application": false,
        //     "access_workbench_application": false,
        //     "access_casefiles_application": true,
        //     "access_helpcenter_application": true,
        //     "address": null,
        //     "birthday": null,
        //     "company": null,
        //     "title": null,
        //     "active_channel": 1,
        //     "default_catalog_id": 1,
        //     "default_catalog": "Constitution_Document_00003",
        //     "active_chat_contact_id": 1,
        //     "available_tokens": 0,
        //     "used_tokens": 0,
        //     "count_of_chatbots": 0
        //   }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void
    {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if ( navigation )
        {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
