import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AmicusConversation, AmicusChat, AmicusChatMessagePayload, FeedbackMessageObject, AmicusChatContact, FeedbackCategory, FeedbackSubcategory, ChatbotableOfContentsWithTitles } from 'app/modules/admin/apps/chat/chat.types';
import { Subject, BehaviorSubject, filter, catchError, map, Observable, of, switchMap, take, tap, throwError, ReplaySubject } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { WebSocketService } from 'app/modules/admin/apps/chat/amicus.socket';


import { AmicusUser } from 'app/core/user/user.types';


import { Chat, Contact, Profile } from 'app/modules/admin/apps/chat/chat.types';

import { globals } from 'app/globals';

import { environment } from 'environment/environment.prod';
import { User } from 'stream-chat';

// import { CustomSocket } from 'app/amicus.socket'

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json' ,
    })
  };

@Injectable({providedIn: 'root'})
export class AmicusChatService
{

    private _conversation: BehaviorSubject<AmicusConversation> = new BehaviorSubject(null);
    private _conversations: BehaviorSubject<AmicusConversation[]> = new BehaviorSubject(null);

    private _contact: BehaviorSubject<AmicusChatContact> = new BehaviorSubject(null);
    private _contacts: BehaviorSubject<AmicusChatContact[]> = new BehaviorSubject(null);

    private _chat: BehaviorSubject<AmicusChat> = new BehaviorSubject(null);
    private _chats: BehaviorSubject<AmicusChat[]> = new BehaviorSubject(null);

    SERVER_URL      = globals.CORE_SERVER_URL
    SOCKET_URL      = globals.CORE_SOCKET_URL
    SERVER_PORT     = globals.CORE_SERVER_PORT

    // WEB_SOCKET_URL = this.SOCKET_URL+':'+this.SERVER_PORT+'/ws/chat/';
    WEB_SOCKET_URL = this.SOCKET_URL+'/ws/chat/';
    TOKEN_WEB_SOCKET_URL = this.SOCKET_URL+'/ws/atokens/';
    USED_TOKEN_BALANCE_WEB_SOCKET_URL = this.SOCKET_URL+'/ws/utokens/';
    LOYALTY_POINTS_BALANCE_WEB_SOCKET_URL = this.SOCKET_URL+'/ws/lpoints/';
    CHATBOT_COUNT_BALANCE_WEB_SOCKET_URL = this.SOCKET_URL+'/ws/chatbotsc/';


    private token_socket: WebSocket;
    private tokenBalanceSubject = new Subject<number>();
    private usedTokenBalanceSubject = new Subject<number>();
    private loyaltyPointsBalanceSubject = new Subject<number>();
    private countOfChatbotsBalanceSubject = new Subject<number>();


    // private _achats: ReplaySubject<AmicusChat[]> = new ReplaySubject<AmicusChat[]>(1);

    // Get the chat conversations
    private _getAmicusChatConversationsURL                      = environment.API_BASE_URL+'/amicus-chat/conversations' //this.SERVER_URL+':'+this.SERVER_PORT+'/amicus-chat/conversations'
    private _getAmicusChatUserConversationsByContactIDURL       = environment.API_BASE_URL+'/amicus-chat/user-conversations-by-contact-id' //this.SERVER_URL+':'+this.SERVER_PORT+'/amicus-chat/user-conversations'
    private _getAmicusDaoUserProfileByContactIDURL              = environment.API_BASE_URL+'/amicus-chat/dao-user-profile-by-contact-id'
    private _getAmicusChatContactDetailsByContactIDURL          = environment.API_BASE_URL+'/amicus-chat/contact-by-contact-id'
    private _getAmicusConversationsByConversationIDURL          = environment.API_BASE_URL+'/amicus-chat/address-book-conversations-by-cuid'
    private _getAmicusAddressBookConversationsByUIDURL          = environment.API_BASE_URL+'/amicus-chat/address-book-conversations-by-uid'
    // private _getAmicusUniqueAddressBookConversationsByUIDURL    = environment.API_BASE_URL+'/amicus-chat/unique-address-book-conversations-by-uid'
    private _getAmicusChatConversationsByConversationIdURL      = environment.API_BASE_URL+'/amicus-chat/by-conversation-id' //this.SERVER_URL+':'+this.SERVER_PORT+'/amicus-chat/by-conversation-id'

    // Get the chat messages
    private _getAmicusChatMessagesURL                           = environment.API_BASE_URL+'/amicus-chat/messages' //this.SERVER_URL+':'+this.SERVER_PORT+'/amicus-chat/messages'
    private _getAmicusChatUserMessagesURL                       = environment.API_BASE_URL+'/amicus-chat/user-messages' //this.SERVER_URL+':'+this.SERVER_PORT+'/amicus-chat/user-messages'
    private _defaultUserChatMessagesURL                         = environment.API_BASE_URL+'/amicus-chat/user-default-messages' //this.SERVER_URL+':'+this.SERVER_PORT+'/amicus-chat/user-default-messages'
    private _getAmicusChatUserMessagesByConversationURL         = environment.API_BASE_URL+'/amicus-chat/user-messages-by-conversation' //this.SERVER_URL+':'+this.SERVER_PORT+'/amicus-chat/user-messages-by-conversation'
    private _getAmicusChatMessagesByUserIdURL                   = environment.API_BASE_URL+'/amicus-chat/by-user-id' //this.SERVER_URL+':'+this.SERVER_PORT+'/amicus-chat/by-user-id'
    private _getAmicusChatMessagesByConversationIdURL           = environment.API_BASE_URL+'/amicus-chat/messages-by-conversation-id' //this.SERVER_URL+':'+this.SERVER_PORT+'/amicus-chat/messages-by-conversation-id'
    private _getAmicusChatMessagesByConversationIdAndUserIdURL  = environment.API_BASE_URL+'/amicus-chat/messages-by-conversation-id-uid' //this.SERVER_URL+':'+this.SERVER_PORT+'/amicus-chat/messages-by-conversation-id-uid'

    // private _saveNewChatMessageURL                           = environment.API_BASE_URL+'/api/amicus-messages/' //this.SERVER_URL+':'+this.SERVER_PORT+'/api/amicus-messages/'
    private _saveNewChatMessageURL                              = environment.API_BASE_URL+'/amicus-chat/new-message' //this.SERVER_URL+':'+this.SERVER_PORT+'/amicus-chat/new-message'

    // Amicus Chat Contacts
    // private _getAllAmicusChatContactsURL                     = environment.API_BASE_URL+'/api/amicus-chat-contacts/'
    private _getAmicusContactsByUserIdURL                       = environment.API_BASE_URL+'/amicus-chat/contact-by-user-id'

    // Create new chat conversation
    private _createNewChatConversationURL                       = environment.API_BASE_URL+'/amicus-chat/create-new-conversation'

    // Get the conversation details
    private _getAmicusConversationDetailsByContactIDURL         = environment.API_BASE_URL+'/amicus-chat/conversation-details-by-contact-id'
    private _getAmicusConversationDetailsByConversationIDURL    = environment.API_BASE_URL+'/amicus-chat/conversation-details-by-conversation-id'

    // Get the list of categories
    private _getFeedbackCategoriesURL                           = environment.API_BASE_URL+'/feedback-categories/active-list'

    // Get the list of Subcategories
    private _getFeedbackSubcategoriesByDomainIdURL              = environment.API_BASE_URL+'/feedback-subcategories/by-feedback-category-id'

    // Update feedback message
    private _updateChatMessageWithFeedbackURL                   = environment.API_BASE_URL+'/amicus-chat/update-message-with-feedback'

    // MPESA Transactions
    private _mpesaTokenPurchaseRequestURL                       = environment.API_BASE_URL+'/amicus-chat/mpesa-purchase-token-requests' //this.SERVER_URL+':'+this.SERVER_PORT+'/api/legal-requests/'
    private _mpesaTokenDonationRequestURL                       = environment.API_BASE_URL+'/amicus-chat/mpesa-donation-token-requests'

    // Get the chatbot table of content
    private _getAmicusChatbotTableOfContentsByChatbotContactIDURL  = environment.API_BASE_URL+'/amicus-chat/chatbot-toc-list-by-contact-id'

    // Update loyalty points and tokens data
    private _updateLoyaltyPointsAndTokensDataURL                   = environment.API_BASE_URL+'/amicus-chat/update-loyalty-points-and-tokens-data'


    private _profile: BehaviorSubject<Profile> = new BehaviorSubject(null);

    // For Chatting
    private socket$: WebSocketSubject<any>;

    // For Updates
    private socket_balance_update$: WebSocketSubject<any>;
    private socket_used_token_balance_update$: WebSocketSubject<any>;
    private socket_loyalty_points_balance_update$: WebSocketSubject<any>;
    private socket_count_of_chatbots_update$: WebSocketSubject<any>;

    socket:WebSocket;
    token_balance_socket:WebSocket;
    used_token_balance_socket:WebSocket;
    loyalty_points_balance_socket:WebSocket;
    chatbot_count_balance_socket:WebSocket;

    new_message_id: "";
    message_id: "";
    new_message_chat_conversation: "";
    new_message_user: "";
    new_message_contact: "";
    new_message_is_mine: false;
    new_message_chat_message: "";
    new_message_created_at: "2024-07-19T07:38:06.398020Z";

    amicusReply = {
        chat_conversation : '',
        contact : '',
        from_party : '',
        to_party : '',
        is_mine : false,
        chat_message : '',
        user : '',
        conversation_id : '',
        message_type : '',
        contact_type : '',
        contact_code : '',
        actual_incoming_tokens : '',
        cost_of_message : '',
        estimated_incoming_outgoing_tokens : '',
        estimated_incoming_tokens : '',
        outgoing_tokens : '',
        loyalty_points : '',
        points_earned : '',
        token_balance_after : '',
        token_balance_before : '',
        total_tokens : '',
        estimated_cost_of_incoming_message : '',
        estimated_cost_of_incoming_outgoing_message : '',
        rate_per_token : '',
        points_factor : '',
        available_tokens : '',
        used_tokens : ''
    }

    // amicusReply = {
    //     chat_conversation : '',
    //     contact  : '',
    //     from_party: "",
    //     to_party: "",
    //     is_mine: false,
    //     chat_message: '',
    //     user: '',
    //     conversation_id: '',
    //     message_type: '',
    //     contact_type: '',
    //     contact_code: '',
    //     actual_incoming_tokens: '',
    //     cost_of_message: '',
    //     estimated_incoming_outgoing_tokens: '',
    //     estimated_incoming_tokens: '',
    //     points_earned: '',
    //     token_balance_after: '',
    //     token_balance_before: '',
    //     total_tokens: ''
    // }

    saved_message = {
        "id": 0,
        "chat_conversation": 0,
        "user": 0,
        "contact": 0,
        "is_mine": true,
        "chat_message": "0",
        "created_at": "0"
      }

    generated_response = "";
    file_object_id = "";
    average_score = "";
    reference_string = "";
    responding_model = "";

    outgoing_token_count = "";
    estimated_incoming_token_count = "";
    total_estimated_tokens = "";
    estimated_amount_to_purchase = "";
    total_expected_bill = "";


    average_score_string = "";
    my_reference_string = "";

    chatMessage = {
        'chat_conversation' : '',
        'contact' : '',
        'from_party' : '',
        'to_party' : '',
        'is_mine' : true,
        'chat_message' : '',
        'user' : '',
        'conversation_id' : '',
        'message_type' : '',
        'contact_type' : '',
        'contact_code' : '',
        'actual_incoming_tokens' : '',
        'cost_of_message' : '',
        'estimated_incoming_outgoing_tokens' : '',
        'estimated_incoming_tokens' : '',
        'outgoing_tokens' : '',
        'loyalty_points' : '',
        'points_earned' : '',
        'token_balance_after' : '',
        'token_balance_before' : '',
        'total_tokens' : '',
        'estimated_cost_of_incoming_message' : '',
        'estimated_cost_of_incoming_outgoing_message' : '',
        'rate_per_token' : '',
        'points_factor' : '',
        'available_tokens' : '',
        'used_tokens' : ''
    }

    // chatMessage =  {
    //     chat_conversation : '',
    //     contact  : '',
    //     from_party: '',
    //     to_party: "1",
    //     is_mine: true,
    //     chat_message: '',
    //     user: '',
    //     conversation_id: '',
    //     message_type: '1',
    //     contact_type: '',
    //     contact_code: '',
    //     actual_incoming_tokens: '',
    //     cost_of_message: '',
    //     estimated_incoming_outgoing_tokens: '',
    //     estimated_incoming_tokens: '',
    //     outgoing_tokens: '',
    //     points_earned: '',
    //     token_balance_after: '',
    //     token_balance_before: '',
    //     total_tokens: '',
    //     estimated_cost_of_incoming_message: '',
    //     estimated_cost_of_incoming_outgoing_message: '',
    // }

    amicusChatResponse = {
        chat_conversation: '',
        contact: '',
        from_party: '',
        to_party: '',
        is_mine: false,
        chat_message: '',
        user: '',
        conversation_id: '',
        message_type: "1",
        contact_type: '',
        contact_code: '',
        amicusReply: ''
    }

    // amicusChatResponse = {
    //     chat_conversation : '',
    //     contact  : '',
    //     from_party: '',
    //     to_party: "1",
    //     is_mine: true,
    //     chat_message: '',
    //     user: '',
    //     conversation_id: '',
    //     message_type: '1',
    //     contact_type: '',
    //     contact_code: '',
    //     actual_incoming_tokens: '',
    //     cost_of_message: '',
    //     estimated_incoming_outgoing_tokens: '',
    //     estimated_incoming_tokens: '',
    //     outgoing_tokens: '',
    //     points_earned: '',
    //     token_balance_after: '',
    //     token_balance_before: '',
    //     total_tokens: '',
    //     estimated_cost_of_incoming_message: '',
    //     estimated_cost_of_incoming_outgoing_message: '',
    //     amicusReply: ''
    // }

    chat_conversation = ""
    contact = ""
    from_party: ""
    to_party: ""
    is_mine = false
    chat_message = ""
    user = ""
    conversation_id = ""
    message_type = ""
    contact_code = ""
    contact_type = ""
    catalog_code = ""
    model_name = ""
    top_k = ""
    temperature = ""
    actual_incoming_tokens = ""
    cost_of_message = ""
    estimated_incoming_outgoing_tokens = ""
    estimated_incoming_tokens = ""
    outgoing_tokens = ""
    loyalty_points = ""
    points_earned = ""
    token_balance_after = ""
    token_balance_before = ""
    total_tokens = ""
    estimated_cost_of_incoming_message = ""
    estimated_cost_of_incoming_outgoing_message = ""
    rate_per_token = ""
    points_factor = ""
    available_tokens = ""
    used_tokens = ""

    saved_response_message_push_object = {
        "id": "0",
        "chat_conversation": "0",
        "contact__id": "0",
        "contact__name": "",
        "contact__about": "",
        "contact__user__id": "0",
        "contact__user__first_name": "",
        "contact__user__last_name": "",
        "contact__user__avatar": "",
        "is_mine": true,
        "chat_message": "",
        "created_at": ""
      }

    // Accuracy
    saved_accuracy_message = {
        "id": 0,
        "chat_conversation": 0,
        "user": 0,
        "contact": 0,
        "is_mine": true,
        "chat_message": "0",
        "created_at": "0"
      }

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
    )
    {
        this.socket = new WebSocket(this.WEB_SOCKET_URL);
        this.setsock()

        // ======================== This socket is for webchat ================================
        this.socket$ = webSocket({
            // use wss for https support
            // 'url': this.SOCKET_URL+':'+this.SERVER_PORT+'/ws/chat/'
            'url': this.SOCKET_URL+'/ws/chat/'
          });

        this.socket$.subscribe(
          message => {
            this.receiveMessage(message,this._httpClient,this._saveNewChatMessageURL)

            console.log("SOCKET - 01 - The recceived message via the socketis ::::: "+JSON.stringify(message))

            },
            err => console.error(err),
            () => console.warn('Completed - Amicus has successfully issued a response!')
        );
        // ======================== End This socket is for webchat ================================

        // ======================== This socket is for token balance ================================
        this.token_balance_socket = new WebSocket(this.TOKEN_WEB_SOCKET_URL)
        this.set_token_balance_socket();

        this.socket_balance_update$ = webSocket({
            // use wss for https support
            // 'url': this.SOCKET_URL+':'+this.SERVER_PORT+'/ws/chat/'
            'url': this.SOCKET_URL+'/ws/atokens/'
          });

        this.socket_balance_update$.subscribe(
            message => {
              //   this.receiveMessage(message,this._httpClient,this._saveNewChatMessageURL)

              console.log("Token Balance Update SOCKET - 01 - The recceived message is ::::: "+JSON.stringify(message))
              },
              err => console.error(err),
              () => console.warn('Completed - Amicus has successfully issued a token balance update response!')
          );

        // ======================== This socket is for token balance ================================

        // ======================== This socket is for used token balance ================================
        this.used_token_balance_socket = new WebSocket(this.USED_TOKEN_BALANCE_WEB_SOCKET_URL)
        this.set_used_token_balance_socket();

        this.socket_used_token_balance_update$ = webSocket({
            // use wss for https support
            // 'url': this.SOCKET_URL+':'+this.SERVER_PORT+'/ws/chat/'
            'url': this.SOCKET_URL+'/ws/utokens/'
          });

        this.socket_used_token_balance_update$.subscribe(
            message => {
              //   this.receiveMessage(message,this._httpClient,this._saveNewChatMessageURL)

              console.log("Used Token Balance Update SOCKET - 01 - The recceived message is ::::: "+JSON.stringify(message))
              },
              err => console.error(err),
              () => console.warn('Completed - Amicus has successfully issued a used token balance update response!')
          );

        // ======================== This socket is for used token balance ================================

        // ======================== This socket is for loyalty points balance ================================
        this.loyalty_points_balance_socket = new WebSocket(this.LOYALTY_POINTS_BALANCE_WEB_SOCKET_URL)
        this.set_loyalty_points_balance_socket();

        this.socket_loyalty_points_balance_update$ = webSocket({
            // use wss for https support
            // 'url': this.SOCKET_URL+':'+this.SERVER_PORT+'/ws/chat/'
            'url': this.SOCKET_URL+'/ws/lpoints/'
          });

        this.socket_loyalty_points_balance_update$.subscribe(
            message => {
              //   this.receiveMessage(message,this._httpClient,this._saveNewChatMessageURL)

              console.log("Used Loyalty Points Balance Update SOCKET - 01 - The recceived message is ::::: "+JSON.stringify(message))
              },
              err => console.error(err),
              () => console.warn('Completed - Amicus has successfully issued a loyalty points balance update response!')
          );

        // ======================== This socket is for used token balance ================================

        // ======================== This socket is for count of chatbots balance ================================
        this.chatbot_count_balance_socket = new WebSocket(this.CHATBOT_COUNT_BALANCE_WEB_SOCKET_URL)
        this.set_count_of_chatbots_balance_socket();

        this.socket_count_of_chatbots_update$ = webSocket({
            // use wss for https support
            // 'url': this.SOCKET_URL+':'+this.SERVER_PORT+'/ws/chat/'
            'url': this.SOCKET_URL+'/ws/chatbotsc/'
          });

        this.socket_count_of_chatbots_update$.subscribe(
            message => {
              //   this.receiveMessage(message,this._httpClient,this._saveNewChatMessageURL)

              console.log("Count of Chatbots Update SOCKET - 01 - The recceived message is ::::: "+JSON.stringify(message))
              },
              err => console.error(err),
              () => console.warn('Completed - Amicus has successfully issued a count of chatbot update response!')
          );

        // ======================== This socket is for used token balance ================================

    }

    // private connect_token_balance_socket() {

    //     this.token_balance_socket = new WebSocket(this.TOKEN_WEB_SOCKET_URL);

    //     this.token_balance_socket.onmessage = (event) => {
    //         const data = JSON.parse(event.data);
    //         if (data.available_tokens !== undefined) {
    //             console.log("The token balance is "+data.available_tokens)
    //             console.log("JSON Sringified Version "+JSON.stringify(data.available_tokens))
    //         this.tokenBalanceSubject.next(data.available_tokens);
    //         }
    //     };
    // }

    getAvailableTokensBalanceUpdates(): Observable<number> {
        return this.tokenBalanceSubject.asObservable();
    }

    getUsedTokensBalanceUpdates(): Observable<number> {
        return this.usedTokenBalanceSubject.asObservable();
    }

    getLoyaltyPointsBalanceUpdates(): Observable<number> {
        return this.loyaltyPointsBalanceSubject.asObservable();
    }

    getCountOfChatbotsBalanceUpdates(): Observable<number> {
        return this.countOfChatbotsBalanceSubject.asObservable();
    }

    isOpen(ws)
    {
        return ws.readyState === ws.OPEN
    }

    /**
     * Use of Byte-Pair-Encoding which breaks down text into sub-word units, not just words separated by spaces.*
     * Each word or sub-word unit (including punctuation and spaces) counts as one token.
     * ***/
    countTokens(text: string): number {

        // Trim whitespace and split text by spaces to count tokens
        const tokens = text.trim().split(/\s+/);

        // Using gpt-3-encoder
        // const tokens = encode(text);
        return tokens.length;
      }

    // billing function based on token count
    calculateBilling(tokenCount: number, ratePerToken: number): number {
        return tokenCount * ratePerToken;
    }

    // This is to set the token balance in real-time
    set_token_balance_socket() {

        this.token_balance_socket = new WebSocket(this.TOKEN_WEB_SOCKET_URL);

        // this.socket = new WebSocket(this.SOCKET_URL+':'+this.SERVER_PORT+'/ws/chat/');
        console.log("The token websocket url is :: "+this.TOKEN_WEB_SOCKET_URL)

        this.token_balance_socket.onopen = () => {
            console.log('WebSocket connection for updating socket balance has been established successfuly!');
        };

        this.token_balance_socket.onmessage = (event) => {
            //  var data = JSON.parse(event.data);
            const parsed_data = JSON.parse(event.data);

            console.log("The token balance parsed message is :: "+JSON.stringify(parsed_data))

            if (parsed_data.available_tokens !== undefined) {
                console.log("The available tokens is :: "+parsed_data.available_tokens)
                this.tokenBalanceSubject.next(parsed_data.available_tokens);
            }

        };

    }

    // This is to set the used token balance in real-time
    set_used_token_balance_socket() {

        this.used_token_balance_socket = new WebSocket(this.USED_TOKEN_BALANCE_WEB_SOCKET_URL);

        // this.socket = new WebSocket(this.SOCKET_URL+':'+this.SERVER_PORT+'/ws/chat/');
        console.log("The used token websocket url is :: "+this.USED_TOKEN_BALANCE_WEB_SOCKET_URL)

        this.used_token_balance_socket.onopen = () => {
            console.log('WebSocket connection for updating used token balance has been established successfuly!');
        };

        this.used_token_balance_socket.onmessage = (event) => {
            //  var data = JSON.parse(event.data);
            const parsed_data = JSON.parse(event.data);

            console.log("The used token balance parsed message is :: "+JSON.stringify(parsed_data))

            if (parsed_data.used_tokens !== undefined) {
                console.log("The used tokens is :: "+parsed_data.used_tokens)
                this.usedTokenBalanceSubject.next(parsed_data.used_tokens);
            }

        };

    }

    // This is to set the loyalty points balance in real-time
    set_loyalty_points_balance_socket() {

        this.loyalty_points_balance_socket = new WebSocket(this.LOYALTY_POINTS_BALANCE_WEB_SOCKET_URL);

        // this.socket = new WebSocket(this.SOCKET_URL+':'+this.SERVER_PORT+'/ws/chat/');
        console.log("The loyalty points websocket url is :: "+this.LOYALTY_POINTS_BALANCE_WEB_SOCKET_URL)

        this.loyalty_points_balance_socket.onopen = () => {
            console.log('WebSocket connection for updating loyalty points balance has been established successfuly!');
        };

        this.loyalty_points_balance_socket.onmessage = (event) => {
            //  var data = JSON.parse(event.data);
            const parsed_data = JSON.parse(event.data);

            console.log("The loyalty points balance parsed message is :: "+JSON.stringify(parsed_data))

            if (parsed_data.loyalty_points !== undefined) {
                console.log("The used tokens is :: "+parsed_data.loyalty_points)
                this.loyaltyPointsBalanceSubject.next(parsed_data.loyalty_points);
            }

        };

    }

    // This is to set the count of chatbots balance in real-time
    set_count_of_chatbots_balance_socket() {

        this.chatbot_count_balance_socket = new WebSocket(this.CHATBOT_COUNT_BALANCE_WEB_SOCKET_URL);

        // this.socket = new WebSocket(this.SOCKET_URL+':'+this.SERVER_PORT+'/ws/chat/');
        console.log("The count of chatbot websocket url is :: "+this.CHATBOT_COUNT_BALANCE_WEB_SOCKET_URL)

        this.chatbot_count_balance_socket.onopen = () => {
            console.log('WebSocket connection for updating count of chatbot balance has been established successfuly!');
        };

        this.chatbot_count_balance_socket.onmessage = (event) => {
            //  var data = JSON.parse(event.data);
            const parsed_data = JSON.parse(event.data);

            console.log("The coun of chatbot balance parsed message is :: "+JSON.stringify(parsed_data))

            if (parsed_data.count_of_chatbots !== undefined) {
                console.log("The used tokens is :: "+parsed_data.count_of_chatbots)
                this.countOfChatbotsBalanceSubject.next(parsed_data.count_of_chatbots);
            }

        };

    }

    setsock() {

        this.socket = new WebSocket(this.WEB_SOCKET_URL);

        // this.socket = new WebSocket(this.SOCKET_URL+':'+this.SERVER_PORT+'/ws/chat/');
        // console.log("The websocket url is :: "+this.WEB_SOCKET_URL)

        this.socket.onopen = () => {
            console.log('WebSocket connection has been established successfuly!');
        };

        this.socket.onmessage = (event) => {
            //  var data = JSON.parse(event.data);
            const parsed_data = JSON.parse(event.data);

            // console.log("01 - The parsed message is :: "+JSON.stringify(parsed_data))

            // console.log("SOCKET - 02 - data from socket:" + event.data);

            const chat_conversation = this.chatMessage.chat_conversation
            const contact = this.chatMessage.contact
            const from_party = this.chatMessage.from_party
            const to_party = this.chatMessage.to_party
            const is_mine = this.chatMessage.is_mine
            const chat_message = this.chatMessage.chat_message
            const user = this.chatMessage.user
            const conversation_id = this.chatMessage.conversation_id
            const contact_type = this.chatMessage.contact_type
            const contact_code = this.chatMessage.contact_code

            this.amicusChatResponse = {
                chat_conversation: chat_conversation,
                contact: contact,
                from_party: to_party,
                to_party: from_party,
                is_mine: is_mine,
                chat_message: chat_message,
                user: user,
                conversation_id: conversation_id,
                message_type: "1",
                contact_type: contact_type,
                contact_code: contact_code,
                amicusReply: JSON.parse(event.data) // This is the message as recieved from the server
            }

            // this.receiveMessage(event.data,this._httpClient,this._saveNewChatMessageURL)
            this.receiveMessage(this.amicusChatResponse,this._httpClient,this._saveNewChatMessageURL)

            // Write code here to save the response into the database
            // Push the data into the list so that the

            // this.title = event.data;
        };

        this.socket.onclose = function(event) {
            console.log("Websocket connection has been lost!");
        };


        // if (this.socket.readyState == WebSocket.OPEN) {
        //     this.socket.onopen(null);
        // }

    }

    getMessage() {
        return this.socket$.asObservable();
    }

    /**
     * Save new Chat Message
     * @param chatMessage
     */
    sendChatMessage(chatMessage: {
        'chat_conversation': string;
        'contact': string;
        'from_party': string;
        'to_party': string;
        'is_mine': boolean;
        'chat_message': string;
        'user': string;
        'conversation_id': string;
        'message_type':string,
        'contact_type':string,
        'contact_code':string,
        'actual_incoming_tokens':string,
        'cost_of_message':string,
        'estimated_incoming_outgoing_tokens':string,
        'estimated_incoming_tokens':string,
        'outgoing_tokens':string,
        'loyalty_points':string,
        'points_earned':string,
        'token_balance_after':string,
        'token_balance_before':string,
        'total_tokens':string,
        'estimated_cost_of_incoming_message':string,
        'estimated_cost_of_incoming_outgoing_message':string,
        'rate_per_token':string;
        'points_factor':string;
        'available_tokens':string;
        'used_tokens':string;
    })
    {

        // console.log("1. The ChatMessage Payload is "+JSON.stringify(chatMessage))

        // console.log("We are chatting with a chatbot so we send message to the socket")

        const socket_message = {
            "message" : {
                "chat_conversation": chatMessage.chat_conversation,
                "contact":chatMessage.contact,
                "from_party":chatMessage.from_party,
                "to_party":chatMessage.to_party,
                "is_mine":chatMessage.is_mine,
                "chat_message":chatMessage.chat_message,
                "user":chatMessage.user,
                "conversation_id":chatMessage.conversation_id,
                "message_type":chatMessage.message_type,
                "contact_type" : chatMessage.contact_type,
                "catalog_code": chatMessage.contact_code, //"Constitution_Document_00003",
                "model_name": "ollama/llama3.2", //"gpt-3.5-turbo",
                "top_k": "5",
                "temperature":0.3,
                "actual_incoming_tokens":chatMessage.actual_incoming_tokens,
                "cost_of_message":chatMessage.cost_of_message,
                "estimated_incoming_outgoing_tokens": chatMessage.estimated_incoming_outgoing_tokens,
                "estimated_incoming_tokens":chatMessage.estimated_incoming_tokens,
                "outgoing_tokens":chatMessage.outgoing_tokens,
                "loyalty_points":chatMessage.loyalty_points,
                "points_earned": chatMessage.points_earned,
                "token_balance_after": chatMessage.token_balance_after,
                "token_balance_before":chatMessage.token_balance_before,
                "total_tokens":chatMessage.total_tokens,
                "estimated_cost_of_incoming_message":chatMessage.estimated_cost_of_incoming_message,
                "estimated_cost_of_incoming_outgoing_message":chatMessage.estimated_cost_of_incoming_outgoing_message,
                "rate_per_token":chatMessage.rate_per_token,
                "points_factor":chatMessage.points_factor,
                "available_tokens": chatMessage.available_tokens,
                "used_tokens":chatMessage.used_tokens
            }
        }

        // console.log("The socker message being sent is :: "+JSON.stringify(socket_message))

        this.chatMessage = chatMessage;

        // console.log("The message object to be sent is : "+ JSON.stringify(chatMessage))

        if (!this.isOpen(this.socket)){
            // console.log("The connection is no longer open!")

            // delete the old socket connection
            this.socket = null // Discard the old connection and re-establish a new one

            // Recreate the socket
            this.setsock()

            // console.log("Reconnection successfully established with the server")
            // console.log("The chat message object is :: "+JSON.stringify(this.chatMessage))
            // console.log("The socket message is :: "+JSON.stringify(socket_message))

            // Send message to LLM and Save to the Database
            setTimeout(this.sendSocketMessage,5000,this.socket,socket_message,this.chatMessage,this._httpClient,this._saveNewChatMessageURL,this._chats)

        } else {
            // console.log("The connection is open and we are ready to send the message through the socket!")

            // Send message to LLM and Save to the Database
            this.sendSocketMessage(this.socket,socket_message,this.chatMessage,this._httpClient,this._saveNewChatMessageURL,this._chats)

        }

    }

    /**
     * Save a peer connection a new message
     * @param chatMessage
     */
    sendPeerChatMessage(chatMessage: {
        chat_conversation: string;
        contact: string;
        from_party: string;
        to_party: string;
        is_mine: boolean;
        chat_message: string;
        user: string;
        conversation_id: string;
        message_type:string,
        contact_type:string,
        contact_code:string
    })
    {

        // console.log("2. The ChatMessage Payload is "+JSON.stringify(chatMessage))

        // console.log("These are peers chatting so we just need to save the message")

        this._httpClient.post(this._saveNewChatMessageURL, chatMessage, httpOptions).subscribe(
            newMessage => {

                // console.log("The new message is :: "+JSON.stringify(newMessage))
                this.new_message_id = newMessage["data"]["id"]
                this.new_message_chat_conversation = newMessage["data"]["chat_conversation"]
                this.new_message_user = newMessage["data"]["user"]
                this.new_message_contact = newMessage["data"]["contact"]
                this.new_message_is_mine = newMessage["data"]["is_mine"]
                this.new_message_chat_message = newMessage["data"]["chat_message"]
                this.new_message_created_at = newMessage["data"]["created_at"]

                this.saved_message = {
                    "id": newMessage["data"]["id"],
                    "chat_conversation":newMessage["data"]["chat_conversation"],
                    "user": newMessage["data"]["user"],
                    "contact": newMessage["data"]["contact"],
                    "is_mine": newMessage["data"]["is_mine"],
                    "chat_message": newMessage["data"]["chat_message"],
                    "created_at": newMessage["data"]["created_at"]
                    }

                // Based on the response after saving, add the details into the array
                this.saved_response_message_push_object = {
                    "id": newMessage["data"]["id"],
                    "chat_conversation": newMessage["data"]["chat_conversation"],
                    "contact__id": newMessage["data"]["contact"],
                    "contact__name": "",
                    "contact__about": "",
                    "contact__user__id": newMessage["data"]["user"],
                    "contact__user__first_name": "",
                    "contact__user__last_name": "",
                    "contact__user__avatar": "",
                    "is_mine": newMessage["data"]["is_mine"],
                    "chat_message": newMessage["data"]["chat_message"],
                    "created_at": newMessage["data"]["created_at"]
                    }

                // Add the generated response into the chat view
                const currentMessages = this._chats.value;
                this._chats.next([...currentMessages, this.saved_response_message_push_object]);

            }
        );

    }

    sendSocketMessage(socket, socket_message,chatMessage,httpClient,saveNewChatMessageURL,chats){
        // console.log("XXXXXXthe socket object is :: "+JSON.stringify(socket))
        // console.log("XXXXXXthe chat message object is :: "+JSON.stringify(chatMessage))
        // console.log("XXXXXXthe socket message is :: "+JSON.stringify(socket_message))

        // Before sending it to he LLM, write code to save the message into the daatabase.
        // Only after saving should you send the request to the LLM
        // Save the message into the database
        httpClient.post(saveNewChatMessageURL, chatMessage, httpOptions).subscribe(
            newMessage => {

                // console.log("The chatMessage payload is :: "+JSON.stringify(chatMessage))
                // console.log("===========================")
                // console.log("The new message from the database is :: "+JSON.stringify(newMessage))
                // console.log("===========================")
                this.new_message_id = newMessage["data"]["id"]
                this.new_message_chat_conversation = newMessage["data"]["chat_conversation"]
                this.new_message_user = newMessage["data"]["user"]
                this.new_message_contact = newMessage["data"]["contact"]
                this.new_message_is_mine = newMessage["data"]["is_mine"]
                this.new_message_chat_message = newMessage["data"]["chat_message"]
                this.new_message_created_at = newMessage["data"]["created_at"]

                // Attach the message id to the socket payload
                socket_message['message']['message_id'] = newMessage["data"]["id"]

                // console.log("The new socket message with message id is "+JSON.stringify(socket_message))

                // Send this message to LLM for response generation
                socket.send(JSON.stringify(socket_message))

                this.saved_message = {
                    "id": newMessage["data"]["id"],
                    "chat_conversation":newMessage["data"]["chat_conversation"],
                    "user": newMessage["data"]["user"],
                    "contact": newMessage["data"]["contact"],
                    "is_mine": newMessage["data"]["is_mine"],
                    "chat_message": newMessage["data"]["chat_message"],
                    "created_at": newMessage["data"]["created_at"]
                }

                // Based on the response after saving, add the details into the array
                this.saved_response_message_push_object = {
                    "id": newMessage["data"]["id"],
                    "chat_conversation": newMessage["data"]["chat_conversation"],
                    "contact__id": newMessage["data"]["contact"],
                    "contact__name": "",
                    "contact__about": "",
                    "contact__user__id": newMessage["data"]["user"],
                    "contact__user__first_name": "",
                    "contact__user__last_name": "",
                    "contact__user__avatar": "",
                    "is_mine": newMessage["data"]["is_mine"],
                    "chat_message": newMessage["data"]["chat_message"],
                    "created_at": newMessage["data"]["created_at"]
                }

                // Add the generated response into the chat view
                const currentMessages = chats.value;
                chats.next([...currentMessages, this.saved_response_message_push_object]);

                // // What we need to calculate and save in the database
                // const outgoingTokens = this.chatMessage.outgoing_tokens //this.countTokens(this.chatMessage.chat_message);
                // // console.log("The outgoing tokens which is also the used tokens is "+outgoingTokens)

                // // 2. cost_of_message - this is derived from adding the outgoing_tokens to incoming tokens and then calculating the costs using the applicable rate
                // // console.log("the cost of this message is :: "+this.chatMessage.cost_of_message)

                // // 3. estimated_incoming_outgoing_tokens - adding outgoing tokens to the estimated incoming
                // // 4. estimated_incoming_tokens - this is the estimated tokens based on the message reply
                // // 5. outgoing_tokens - this is the tokens as sent by the customer

                // // 6. points_earned - this is the calculated points. This will be points earned on this incoming message
                // const outgoing_message_points_earned = this.chatMessage.points_earned // outgoingBillAmount / parseFloat(this.points_factor)
                // // console.log("The outgoing message points earned as a result of sending this message is "+outgoing_message_points_earned)

                // // 7. token_balance_after - this is the token balance after the incoming tokens have been deducted
                // const token_balance_after = this.chatMessage.token_balance_after//newMessage["data"]["token_balance_before"] //parseInt(this.available_tokens) - outgoingTokens
                // // console.log("The token balance after sending this message is "+token_balance_after)

                // // 8. token_balance_before - this is the tokens as they are before the user sent the outgoing message i.e. available_tokens
                // const token_balance_before = this.chatMessage.token_balance_before //newMessage["data"]["token_balance_before"]  //parseInt(this.available_tokens)
                // // console.log("The token balance before sending this message is :: "+token_balance_before)

                // // 9. total_tokens - this is the actual tokens (outgoing_tokens + incoming_tokens)
                // const total_tokens = this.chatMessage.total_tokens //newMessage["data"]["total_tokens"]  //this.outgoing_tokens
                // // console.log("The total tokens used for this specific message is "+total_tokens)

                // // 10. new_loyalty_points - this is the loyalty points after earning new loyalty points
                // // Need to update this value on the profile
                // const new_loyalty_points = parseFloat(this.chatMessage.loyalty_points) + parseFloat(this.chatMessage.points_earned) //this.loyalty_points + outgoing_message_points_earned

                // const agg_used_tokens = parseInt(this.chatMessage.used_tokens) + parseInt(this.chatMessage.outgoing_tokens)// this.used_tokens + total_tokens

                // // Update the loyalty points and the token balance
                // const userLoyaltyPointsAndTokensDataObject = {
                //     "user_id": this.chatMessage.user,
                //     "available_tokens": parseInt(token_balance_after),
                //     "used_tokens": agg_used_tokens,
                //     "loyalty_points": new_loyalty_points
                // }

                // console.log("This is the outgoing userLoyaltyPointsAndTokensDataObject :: "+JSON.stringify(userLoyaltyPointsAndTokensDataObject))

                // // const updated_user_id = this.updateLoyaltyPointsAndTokenBalance(userLoyaltyPointsAndTokensDataObject) //this._httpClient.post(this._updateLoyaltyPointsAndTokensDataURL, userLoyaltyPointsAndTokensDataObject, httpOptions);
                // // console.log("The updated user id is :: "+JSON.stringify(updated_user_id)+" - "+userLoyaltyPointsAndTokensDataObject.user_id)

                // httpClient.post(this._updateLoyaltyPointsAndTokensDataURL, userLoyaltyPointsAndTokensDataObject, httpOptions).subscribe(
                //     updateResults => {

                //         const updated_user_record_id = updateResults["id"]

                //         if(updated_user_record_id == userLoyaltyPointsAndTokensDataObject.user_id){



                //         } else {

                //             console.log("Customer loyalty and token information has not been updated successfully!")

                //         }

                //     }

                // )

            }
        );

    }

    /**
     * Get conversation details by chat contact id
     *
     * @param chat_contact_id
     */
    getConversationByChatContactDetails(logged_in_user,chat_contact_id): Observable<any>
    {
        //return this._httpClient.post(this._meURL, email, httpOptions);
        return this._httpClient.post(this._getAmicusConversationDetailsByContactIDURL, {
            "logged_in_user": logged_in_user,
            "chat_contact_id":chat_contact_id
        }, httpOptions).pipe(

            catchError(() =>

                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
                //console.log("The user profile detail are : "+JSON.stringify(response))

                // Store the user on the user service
                //this._userService.user = response; //cloneDeep(this._user); //response.user;

                // Return a new observable with the response
                return of(response);

            })

        )
    }

    /**
     * Get conversation details by chat conversation_id
     *
     * @param conversation_id
     */
    getConversationDetailsByConversationID(conversation_id): Observable<any>
    {
        //return this._httpClient.post(this._meURL, email, httpOptions);
        return this._httpClient.post(this._getAmicusConversationDetailsByConversationIDURL, {
            "conversation_id": conversation_id
        }, httpOptions).pipe(

            catchError(() =>

                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
                //console.log("The user profile detail are : "+JSON.stringify(response))

                // Store the user on the user service
                //this._userService.user = response; //cloneDeep(this._user); //response.user;

                // Return a new observable with the response
                return of(response);

            })

        )
    }

    // /***
    //  * Update the user profile loyalty points and token balance
    //  *
    //  * ****/
    // updateLoyaltyPointsAndTokenBalance(userLoyaltyPointsAndTokensDataObject: {
    //     user_id: string;
    //     available_tokens: number;
    //     used_tokens: number;
    //     loyalty_points: number;
    // }): Observable<any>
    // {
    //     console.log("The function object is "+JSON.stringify(userLoyaltyPointsAndTokensDataObject))
    //     return this._httpClient.post(this._updateLoyaltyPointsAndTokensDataURL, userLoyaltyPointsAndTokensDataObject, httpOptions)
    // }


    /**
     * Recieve Message
     * @param newMessage
     */
    receiveMessage(amicusMessage,httpClient,saveNewChatMessageURL) {

        // console.log("The received socket message block is :: "+JSON.stringify(amicusMessage))

        const newMessage  = amicusMessage //JSON.parse(amicusMessage)

        // console.log("The new received message is : "+JSON.stringify(newMessage))
        // console.log("==========================================================")
        // console.log("The additional content to be loaded are :: "+JSON.stringify(this.saved_message))

        this.generated_response = newMessage["amicusReply"]["amicus_reply"]["generated_response"]

        this.file_object_id = newMessage["amicusReply"]["amicus_reply"]["payload"]["file_object_id"]
        this.average_score = newMessage["amicusReply"]["amicus_reply"]["payload"]["average_score"]
        this.reference_string = newMessage["amicusReply"]["amicus_reply"]["payload"]["reference_string"]
        this.responding_model = newMessage["amicusReply"]["amicus_reply"]["payload"]["responding_model"]

        this.chat_conversation = newMessage["amicusReply"]["amicus_reply"]["payload"]["chat_conversation"]
        this.contact = newMessage["amicusReply"]["amicus_reply"]["payload"]["contact"]
        this.to_party = newMessage["amicusReply"]["amicus_reply"]["payload"]["to_party"]
        this.from_party = newMessage["amicusReply"]["amicus_reply"]["payload"]["from_party"]
        this.is_mine = newMessage["amicusReply"]["amicus_reply"]["payload"]["is_mine"]
        this.chat_message = newMessage["amicusReply"]["amicus_reply"]["payload"]["chat_message"]
        this.user = newMessage["amicusReply"]["amicus_reply"]["payload"]["user"]
        this.conversation_id = newMessage["amicusReply"]["amicus_reply"]["payload"]["conversation_id"]
        this.message_type = newMessage["amicusReply"]["amicus_reply"]["payload"]["message_type"]

        this.contact_type = newMessage["amicusReply"]["amicus_reply"]["payload"]["contact_type"]
        this.catalog_code = newMessage["amicusReply"]["amicus_reply"]["payload"]["catalog_code"]
        this.model_name = newMessage["amicusReply"]["amicus_reply"]["payload"]["model_name"]
        this.top_k = newMessage["amicusReply"]["amicus_reply"]["payload"]["top_k"]
        this.temperature = newMessage["amicusReply"]["amicus_reply"]["payload"]["temperature"]
        this.actual_incoming_tokens = newMessage["amicusReply"]["amicus_reply"]["payload"]["actual_incoming_tokens"]
        this.cost_of_message = newMessage["amicusReply"]["amicus_reply"]["payload"]["cost_of_message"]
        this.estimated_incoming_outgoing_tokens = newMessage["amicusReply"]["amicus_reply"]["payload"]["estimated_incoming_outgoing_tokens"]
        this.estimated_incoming_tokens = newMessage["amicusReply"]["amicus_reply"]["payload"]["estimated_incoming_tokens"]
        this.outgoing_tokens = newMessage["amicusReply"]["amicus_reply"]["payload"]["outgoing_tokens"]
        this.loyalty_points = newMessage["amicusReply"]["amicus_reply"]["payload"]["loyalty_points"]
        this.points_earned = newMessage["amicusReply"]["amicus_reply"]["payload"]["points_earned"]
        this.token_balance_after = newMessage["amicusReply"]["amicus_reply"]["payload"]["token_balance_after"]
        this.token_balance_before = newMessage["amicusReply"]["amicus_reply"]["payload"]["token_balance_before"]
        this.total_tokens = newMessage["amicusReply"]["amicus_reply"]["payload"]["total_tokens"]
        this.estimated_cost_of_incoming_message = newMessage["amicusReply"]["amicus_reply"]["payload"]["estimated_cost_of_incoming_message"]
        this.estimated_cost_of_incoming_outgoing_message = newMessage["amicusReply"]["amicus_reply"]["payload"]["estimated_cost_of_incoming_outgoing_message"]
        this.message_id = newMessage["amicusReply"]["amicus_reply"]["payload"]["message_id"]
        this.rate_per_token = newMessage["amicusReply"]["amicus_reply"]["payload"]["rate_per_token"]
        this.points_factor = newMessage["amicusReply"]["amicus_reply"]["payload"]["points_factor"]
        this.available_tokens = newMessage["amicusReply"]["amicus_reply"]["payload"]["available_tokens"]
        this.used_tokens = newMessage["amicusReply"]["amicus_reply"]["payload"]["used_tokens"]

        this.saved_response_message_push_object = {
            "id": "0",
            "chat_conversation": this.chat_conversation,
            "contact__id": this.contact,
            "contact__name": "",
            "contact__about": "",
            "contact__user__id": this.user,
            "contact__user__first_name": "",
            "contact__user__last_name": "",
            "contact__user__avatar": "",
            "is_mine": this.is_mine,
            "chat_message": this.generated_response,
            "created_at": "2024-07-18T04:20:05.814215Z"
            }

        // console.log("The object to push is "+JSON.stringify(this.saved_response_message_push_object))

        // What we need to calculate and save in the database
        // 1. actual_incoming_tokens - this is derived from the generated_response
        const actualIncomingTokens = this.countTokens(this.generated_response);

        const token_used = this.used_tokens + actualIncomingTokens

        // 2. cost_of_message - this is derived from adding the outgoing_tokens to incoming tokens and then calculating the costs using the applicable rate
        // const outgoingBillAmount = this.calculateBilling(parseInt(this.outgoing_tokens), parseFloat(this.rate_per_token));
        // const incomingBillAmount = this.calculateBilling(actualIncomingTokens, parseFloat(this.rate_per_token));
        // const cost_of_message = outgoingBillAmount+incomingBillAmount

        // Each message is treated seperately
        const incomingBillAmount = this.calculateBilling(actualIncomingTokens, parseFloat(this.rate_per_token));
        const cost_of_message = incomingBillAmount

        // 3. estimated_incoming_outgoing_tokens - adding outgoing tokens to the estimated incoming
        // 4. estimated_incoming_tokens - this is the estimated tokens based on the message reply
        // 5. outgoing_tokens - this is the tokens as sent by the customer
        // 6. points_earned - this is the calculated points. This will be points earned on this incoming message
        const incoming_message_points_earned = incomingBillAmount / parseFloat(this.points_factor)

        // 7. token_balance_after - this is the token balance after the incoming tokens have been deducted
        const token_balance_after = parseInt(this.available_tokens) - actualIncomingTokens

        // 8. token_balance_before - this is the tokens as they are before the user sent the outgoing message i.e. available_tokens
        const token_balance_before = parseInt(this.available_tokens)

        // 9. total_tokens - this is the actual tokens (outgoing_tokens + incoming_tokens)
        const total_tokens = actualIncomingTokens + this.outgoing_tokens

        // 10. new_loyalty_points - this is the loyalty points after earning new loyalty points
        // Need to update this value on the profile
        // const new_loyalty_points = this.loyalty_points + incoming_message_points_earned

        // const agg_used_tokens = this.used_tokens + total_tokens

        const new_loyalty_points = parseFloat(this.chatMessage.loyalty_points) + incoming_message_points_earned //this.loyalty_points + outgoing_message_points_earned

        const agg_used_tokens = parseInt(this.chatMessage.used_tokens) + total_tokens// this.used_tokens + total_tokens

        const reply_object = {
            "chat_conversation": this.chat_conversation,
            "contact":this.contact,
            "from_party":this.from_party,
            "to_party":this.to_party,
            "is_mine":this.is_mine,
            "chat_message":this.chat_message,
            "user":this.user,
            "conversation_id":this.conversation_id,
            "message_type":this.message_type,
            "contact_type" : this.contact_type,
            "catalog_code": this.contact_code, //"Constitution_Document_00003",
            "model_name": this.model_name,
            "top_k": this.top_k,
            "temperature":this.temperature,
            "actual_incoming_tokens":actualIncomingTokens,
            "cost_of_message":cost_of_message,
            "estimated_incoming_outgoing_tokens": this.estimated_incoming_outgoing_tokens,
            "estimated_incoming_tokens":this.estimated_incoming_tokens,
            "outgoing_tokens":this.outgoing_tokens,
            "loyalty_points":this.loyalty_points,
            "points_earned": this.points_earned,
            "token_balance_after": this.token_balance_after,
            "token_balance_before":this.token_balance_before,
            "total_tokens":this.total_tokens,
            "estimated_cost_of_incoming_message":this.estimated_cost_of_incoming_message,
            "estimated_cost_of_incoming_outgoing_message":this.estimated_cost_of_incoming_outgoing_message
        }

        // // Save the generated response inside the database table
        // this.amicusReply = {
        //     chat_conversation : this.chat_conversation, //localStorage.getItem("chat_conversation"),
        //     contact  : this.contact, //localStorage.getItem("chat_contact"),
        //     from_party : this.from_party,
        //     to_party : this.to_party,
        //     is_mine: false,
        //     chat_message: this.generated_response,
        //     user: this.user,
        //     conversation_id: this.conversation_id,
        //     message_type: this.message_type,
        //     contact_type:this.contact_type,
        //     contact_code: this.contact_code,
        //     actual_incoming_tokens: String(actualIncomingTokens),
        //     cost_of_message: String(cost_of_message),
        //     estimated_incoming_outgoing_tokens: this.estimated_incoming_outgoing_tokens,
        //     estimated_incoming_tokens: this.estimated_incoming_tokens,
        //     points_earned: String(incoming_message_points_earned),
        //     token_balance_after: String(token_balance_after),
        //     token_balance_before: String(token_balance_before),
        //     total_tokens: total_tokens
        // }
        this.amicusReply = {
            chat_conversation: this.chat_conversation,
            contact: this.contact,
            from_party:this.to_party,
            to_party:this.from_party,
            is_mine:false,
            chat_message:this.generated_response,
            user:this.user,
            conversation_id:this.conversation_id,
            message_type:this.message_type,
            contact_type : this.contact_type,
            contact_code: this.contact_code,
            actual_incoming_tokens:String(actualIncomingTokens),
            cost_of_message:String(cost_of_message),
            estimated_incoming_outgoing_tokens: this.estimated_incoming_outgoing_tokens,
            estimated_incoming_tokens:this.estimated_incoming_tokens,
            outgoing_tokens:this.outgoing_tokens,
            loyalty_points:this.loyalty_points,
            points_earned: this.points_earned,
            token_balance_after: this.token_balance_after,
            token_balance_before:this.token_balance_before,
            total_tokens:this.total_tokens,
            estimated_cost_of_incoming_message:this.estimated_cost_of_incoming_message,
            estimated_cost_of_incoming_outgoing_message: this.estimated_cost_of_incoming_outgoing_message,
            rate_per_token:this.rate_per_token,
            points_factor:this.points_factor,
            available_tokens:this.available_tokens,
            used_tokens:token_used
        }

        // console.log("Amicus Chat Service - 1 - The response message to save is "+JSON.stringify(this.amicusReply))

        httpClient.post(saveNewChatMessageURL, this.amicusReply, httpOptions).subscribe(
            newMessage => {

                // console.log("The new message is :: "+JSON.stringify(newMessage))
                this.new_message_id = newMessage["data"]["id"]
                this.new_message_chat_conversation = newMessage["data"]["chat_conversation"]
                this.new_message_user = newMessage["data"]["user"]
                this.new_message_contact = newMessage["data"]["contact"]
                this.new_message_is_mine = newMessage["data"]["is_mine"]
                this.new_message_chat_message = newMessage["data"]["chat_message"]
                this.new_message_created_at = newMessage["data"]["created_at"]

                this.saved_message = {
                    "id": newMessage["data"]["id"],
                    "chat_conversation":newMessage["data"]["chat_conversation"],
                    "user": newMessage["data"]["user"],
                    "contact": newMessage["data"]["contact"],
                    "is_mine": newMessage["data"]["is_mine"],
                    "chat_message": newMessage["data"]["chat_message"],
                    "created_at": newMessage["data"]["created_at"]
                }

                // Based on the response after saving, add the details into the array
                this.saved_response_message_push_object = {
                    "id": newMessage["data"]["id"],
                    "chat_conversation": newMessage["data"]["chat_conversation"],
                    "contact__id": newMessage["data"]["contact"],
                    "contact__name": "",
                    "contact__about": "",
                    "contact__user__id": newMessage["data"]["user"],
                    "contact__user__first_name": "",
                    "contact__user__last_name": "",
                    "contact__user__avatar": "",
                    "is_mine": newMessage["data"]["is_mine"],
                    "chat_message": newMessage["data"]["chat_message"],
                    "created_at": newMessage["data"]["created_at"]
                }

                // Add the generated response into the chat view
                const currentMessages = this._chats.value;
                this._chats.next([...currentMessages, this.saved_response_message_push_object]);

                // Display the Accuracy Message
                this.average_score_string = "My Accuracy :: "+this.average_score

                // Save the average score
                // const amicusReply = {
                //     chat_conversation : this.chat_conversation, //localStorage.getItem("chat_conversation"),
                //     contact  : this.contact, //localStorage.getItem("chat_contact"),
                //     from_party: this.to_party,
                //     to_party: this.from_party,
                //     is_mine: false,
                //     chat_message: this.average_score_string,
                //     user: this.saved_message.user+"",
                //     message_type: "2",
                //     contact_type: this.contact_type,
                //     contact_code: this.contact_code,
                //     conversation_id: this.conversation_id
                // }
                const amicusReply = {
                    chat_conversation: this.chat_conversation,
                    contact: this.contact,
                    from_party:this.to_party,
                    to_party:this.from_party,
                    is_mine:false,
                    chat_message:this.average_score_string,
                    user:this.user,
                    conversation_id:this.conversation_id,
                    message_type:"2",
                    contact_type : this.contact_type,
                    contact_code: this.contact_code,
                    actual_incoming_tokens:String(actualIncomingTokens),
                    cost_of_message:String(cost_of_message),
                    estimated_incoming_outgoing_tokens: this.estimated_incoming_outgoing_tokens,
                    estimated_incoming_tokens:this.estimated_incoming_tokens,
                    outgoing_tokens:this.outgoing_tokens,
                    loyalty_points:this.loyalty_points,
                    points_earned: this.points_earned,
                    token_balance_after: this.token_balance_after,
                    token_balance_before:this.token_balance_before,
                    total_tokens:this.total_tokens,
                    estimated_cost_of_incoming_message:this.estimated_cost_of_incoming_message,
                    estimated_cost_of_incoming_outgoing_message: this.estimated_cost_of_incoming_outgoing_message,
                    rate_per_token:this.rate_per_token,
                    points_factor:this.points_factor,
                    available_tokens:this.available_tokens,
                    used_tokens:this.used_tokens
                }

                this.saved_accuracy_message = {
                    "id": newMessage["data"]["id"],
                    "chat_conversation": newMessage["data"]["chat_conversation"],
                    "user": newMessage["data"]["user"],
                    "contact": newMessage["data"]["contact"],
                    "is_mine": newMessage["data"]["is_mine"],
                    "chat_message": newMessage["data"]["chat_message"],
                    "created_at": newMessage["data"]["created_at"]
                }

                httpClient.post(saveNewChatMessageURL, amicusReply, httpOptions).subscribe(

                    newMessage => {

                        // console.log("The new accuracy message is :: "+JSON.stringify(newMessage))
                        this.new_message_id = newMessage["data"]["id"]
                        this.new_message_chat_conversation = newMessage["data"]["chat_conversation"]
                        this.new_message_user = newMessage["data"]["user"]
                        this.new_message_contact = newMessage["data"]["contact"]
                        this.new_message_is_mine = newMessage["data"]["is_mine"]
                        this.new_message_chat_message = newMessage["data"]["chat_message"]
                        this.new_message_created_at = newMessage["data"]["created_at"]

                        this.saved_message = {
                            "id": newMessage["data"]["id"],
                            "chat_conversation":newMessage["data"]["chat_conversation"],
                            "user": newMessage["data"]["user"],
                            "contact": newMessage["data"]["contact"],
                            "is_mine": newMessage["data"]["is_mine"],
                            "chat_message": newMessage["data"]["chat_message"],
                            "created_at": newMessage["data"]["created_at"]
                        }

                        // Based on the response after saving, add the details into the array
                        this.saved_response_message_push_object = {
                            "id": newMessage["data"]["id"],
                            "chat_conversation": newMessage["data"]["chat_conversation"],
                            "contact__id": newMessage["data"]["contact"],
                            "contact__name": "",
                            "contact__about": "",
                            "contact__user__id": newMessage["data"]["user"],
                            "contact__user__first_name": "",
                            "contact__user__last_name": "",
                            "contact__user__avatar": "",
                            "is_mine": newMessage["data"]["is_mine"],
                            "chat_message": newMessage["data"]["chat_message"],
                            "created_at": newMessage["data"]["created_at"]
                        }

                        // Add the generated response into the chat view
                        const currentMessages = this._chats.value;
                        this._chats.next([...currentMessages, this.saved_response_message_push_object]);

                        // Start displaying the references message
                        this.my_reference_string = "My References :: "+this.reference_string

                        // const amicusReply = {
                        //     chat_conversation : this.chat_conversation, //localStorage.getItem("chat_conversation"),
                        //     contact  : this.contact, //localStorage.getItem("chat_contact"),
                        //     from_party: this.to_party,
                        //     to_party: this.from_party,
                        //     is_mine: false,
                        //     chat_message: this.my_reference_string,
                        //     user: this.saved_message.user+"",
                        //     message_type: "3",
                        //     contact_type: this.contact_type,
                        //     contact_code: this.contact_code,
                        //     conversation_id: this.conversation_id
                        // }
                        const amicusReply = {
                            chat_conversation: this.chat_conversation,
                            contact: this.contact,
                            from_party:this.to_party,
                            to_party:this.from_party,
                            is_mine:false,
                            chat_message:this.my_reference_string,
                            user:this.user,
                            conversation_id:this.conversation_id,
                            message_type:"3",
                            contact_type : this.contact_type,
                            contact_code: this.contact_code,
                            actual_incoming_tokens:String(actualIncomingTokens),
                            cost_of_message:String(cost_of_message),
                            estimated_incoming_outgoing_tokens: this.estimated_incoming_outgoing_tokens,
                            estimated_incoming_tokens:this.estimated_incoming_tokens,
                            outgoing_tokens:this.outgoing_tokens,
                            loyalty_points:this.loyalty_points,
                            points_earned: this.points_earned,
                            token_balance_after: this.token_balance_after,
                            token_balance_before:this.token_balance_before,
                            total_tokens:this.total_tokens,
                            estimated_cost_of_incoming_message:this.estimated_cost_of_incoming_message,
                            estimated_cost_of_incoming_outgoing_message: this.estimated_cost_of_incoming_outgoing_message,
                            rate_per_token:this.rate_per_token,
                            points_factor:this.points_factor,
                            available_tokens:this.available_tokens,
                            used_tokens:this.used_tokens
                        }

                        httpClient.post(saveNewChatMessageURL, amicusReply, httpOptions).subscribe(

                            newMessage => {

                                // console.log("The new reference message is :: "+JSON.stringify(newMessage))
                                this.new_message_id = newMessage["data"]["id"]
                                this.new_message_chat_conversation = newMessage["data"]["chat_conversation"]
                                this.new_message_user = newMessage["data"]["user"]
                                this.new_message_contact = newMessage["data"]["contact"]
                                this.new_message_is_mine = newMessage["data"]["is_mine"]
                                this.new_message_chat_message = newMessage["data"]["chat_message"]
                                this.new_message_created_at = newMessage["data"]["created_at"]

                                this.saved_message = {
                                    "id": newMessage["data"]["id"],
                                    "chat_conversation":newMessage["data"]["chat_conversation"],
                                    "user": newMessage["data"]["user"],
                                    "contact": newMessage["data"]["contact"],
                                    "is_mine": newMessage["data"]["is_mine"],
                                    "chat_message": newMessage["data"]["chat_message"],
                                    "created_at": newMessage["data"]["created_at"]
                                }

                                // Based on the response after saving, add the details into the array
                                this.saved_response_message_push_object = {
                                    "id": newMessage["data"]["id"],
                                    "chat_conversation": newMessage["data"]["chat_conversation"],
                                    "contact__id": newMessage["data"]["contact"],
                                    "contact__name": "",
                                    "contact__about": "",
                                    "contact__user__id": newMessage["data"]["user"],
                                    "contact__user__first_name": "",
                                    "contact__user__last_name": "",
                                    "contact__user__avatar": "",
                                    "is_mine": newMessage["data"]["is_mine"],
                                    "chat_message": newMessage["data"]["chat_message"],
                                    "created_at": newMessage["data"]["created_at"]
                                }

                                // Add the generated response into the chat view
                                const currentMessages = this._chats.value;
                                this._chats.next([...currentMessages, this.saved_response_message_push_object]);

                            }

                        )

                    }
                )

                // // Update the loyalty points and the token balance
                // const userLoyaltyPointsAndTokensDataObject = {
                //     "user_id": this.chatMessage.user,
                //     "available_tokens": token_balance_after,
                //     "used_tokens": parseInt(agg_used_tokens),
                //     "loyalty_points": new_loyalty_points
                // }

                // console.log("This is the outgoing userLoyaltyPointsAndTokensDataObject :: "+JSON.stringify(userLoyaltyPointsAndTokensDataObject))

                // // const updated_user_id =  this.updateLoyaltyPointsAndTokenBalance(userLoyaltyPointsAndTokensDataObject)
                // // console.log("The updated user id is :: "+JSON.stringify(updated_user_id)+" - "+userLoyaltyPointsAndTokensDataObject.user_id)

                // httpClient.post(this._updateLoyaltyPointsAndTokensDataURL, userLoyaltyPointsAndTokensDataObject, httpOptions).subscribe(
                //     updateResults => {

                //         const updated_user_record_id = updateResults["id"]

                //         if(updated_user_record_id == userLoyaltyPointsAndTokensDataObject.user_id){

                //             console.log("The user loyalty and token data has been updated successfully")



                //         } else {

                //             console.log("The user loyalty and token data has NOT been updated successfully")

                //         }

                //     }

                // )

            }
        )

    }


    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for contact
     */
    get contact$(): Observable<AmicusChatContact>
    {
        return this._contact.asObservable();
    }

    /**
     * Getter for contacts
     */
    get contacts$(): Observable<AmicusChatContact[]>
    {
        return this._contacts.asObservable();
    }

    /**
     * Getter for chat
     */
    get chat$(): Observable<AmicusChat>
    {
        // console.log("1. Observable Material")
        return this._chat.asObservable();
    }

    /**
     * Getter for chats
     */
    get chats$(): Observable<AmicusChat[]>
    {
        // console.log("2. Observable Material")
        return this._chats.asObservable();
    }

    // updateChatMessageList(message: AmicusChat){
    //     this._chat.next(message)
    // }

    /**
     * Getter for conversation
     */
    get conversation$(): Observable<AmicusConversation>
    {
        return this._conversation.asObservable();
    }

    /**
     * Getter for conversations
     */
    get conversations$(): Observable<AmicusConversation[]>
    {
        return this._conversations.asObservable();
    }

    /**
     * Getter for profile
     */
    get profile$(): Observable<Profile>
    {
        return this._profile.asObservable();
    }

    /**
     * Get profile
     */
    getProfile(): Observable<any>
    {
        return this._httpClient.get<Profile>('api/apps/chat/profile').pipe(
            tap((response: Profile) =>
            {
                this._profile.next(response);
            }),
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    // /**
    //  * Get Conversations
    //  */
    // getConversations(): Observable<any>
    // {
    //     return this._httpClient.get<AmicusConversation[]>(this._getAmicusChatConversationsURL).pipe(
    //         tap((response: AmicusConversation[]) =>
    //         {
    //             this._conversations.next(response);
    //         }),
    //     );
    // }

    /**
     * Get contact
     *
     * @param id
     */
    getContact(id: string): Observable<any>
    {
        return this._httpClient.get<Contact>('api/apps/chat/contacts', {params: {id}}).pipe(
            tap((response: Contact) =>
            {
                // console.log("ChaService #1 - The retrieved contact by ID ("+id+") is :: "+JSON.stringify(response))
                this._contact.next(response);
            }),
        );
    }

    /**
     * Get contacts
     */
    getContacts(user_id: string): Observable<any>
    {
        return this._httpClient.get<AmicusChatContact[]>(this._getAmicusContactsByUserIdURL+'?user_id='+user_id).pipe(
            tap((response: AmicusChatContact[]) =>
            {
                // console.log("101. The user id is :: "+user_id)
                // console.log("3. userContactsResolver - ChaService #2 ("+user_id+") - The response extracting the contacts from the database is :: "+JSON.stringify(response))
                this._contacts.next(response);
            }),
        );
        // return this._httpClient.get(this._getAmicusContactsByUserIdURL, httpOptions);
    }

    /**
     * Get DAO user profile
     * @param dao_contact_user_id
     */
    getDaoUserProfileByContactId(user_id: string): Observable<any>
    {
        // console.log("========Start Parameters used to extract Contact Info Data (amicus.chat.service)=========")
        // console.log("User ID :: "+user_id)
        // console.log("========End Parameters used to extract Contact Info Data (amicus.chat.service)=========")

        return this._httpClient.get<AmicusUser[]>(this._getAmicusDaoUserProfileByContactIDURL+'?user_id='+user_id).pipe(
            tap((response: AmicusUser[]) =>
            {
                // console.log("===== Start Returned Contact Info Data using the Parameters ========")
                // console.log(JSON.stringify(response))
                // console.log("===== Start Returned Contact Info Data using the Parameters ========")
                this._contacts.next(response);
            }),
        );
    }


    /**
     * Get user default conversation
     * **** This code has been tested and is returning the right data
     */
    getUserDefaultUserConversationByContactId(user_id: string,contact_id: string): Observable<any>
    {
        // console.log("========Start Parameters used to extract Contact Info Data (amicus.chat.service)=========")
        // console.log("User ID :: "+user_id)
        // console.log("Contact ID :: "+contact_id)
        // console.log("========End Parameters used to extract Contact Info Data (amicus.chat.service)=========")

        return this._httpClient.get<AmicusConversation[]>(this._getAmicusChatUserConversationsByContactIDURL+'?user_id='+user_id+'&contact_id='+contact_id).pipe(
            tap((response: AmicusConversation[]) =>
            {
                // console.log("===== Start Returned Contact Info Data using the Parameters ========")
                // console.log(JSON.stringify(response))
                // console.log("===== Start Returned Contact Info Data using the Parameters ========")
                this._contacts.next(response);
            }),
        );
        // return this._httpClient.get(this._getAmicusContactsByUserIdURL, httpOptions);
    }

    /**
     * Get user default conversation
     */
    getChatContactDetailsByContactId(contact_id: string): Observable<any>
    {
        return this._httpClient.get<AmicusChatContact[]>(this._getAmicusChatContactDetailsByContactIDURL+'?contact_id='+contact_id).pipe(
            tap((response: AmicusChatContact[]) =>
            {
                // console.log("101. The user id is :: "+user_id)
                // console.log("ChatService #4 - The chat contact details is :: "+JSON.stringify(response))
                this._contacts.next(response);
            }),
        );
        // return this._httpClient.get(this._getAmicusContactsByUserIdURL, httpOptions);
    }

    /**
     * Get Conversations
     * @param user_id
     */
    getUserConversations(user_id: string): Observable<any>
    {
        return this._httpClient.get<AmicusConversation[]>(this._getAmicusAddressBookConversationsByUIDURL+'?user_id='+user_id).pipe(
            tap((response: AmicusConversation[]) =>
            {
                // console.log("2. userConversationsResolver - ChaService #3 - The user ("+user_id+") specific conversations are :: "+JSON.stringify(response))
                this._conversations.next(response);
            }),
        );
    }

    /**
     * Get Conversations _getAmicusConversationsByConversationIDURL
     * @param user_id
     */
    getUserConversationsByConversationId(conversation_uid: string): Observable<any>
    {
        return this._httpClient.get<AmicusConversation[]>(this._getAmicusConversationsByConversationIDURL+'?conversation_uid='+conversation_uid).pipe(
            tap((response: AmicusConversation[]) =>
            {
                // console.log("ChaService #4 - The conversation uid ("+conversation_uid+") specific conversations are :: "+JSON.stringify(response))
                this._conversations.next(response);
            }),
        );
    }

    // /**
    //  * Get chats
    //  */
    getChats(): Observable<any>
    {
        return this._httpClient.get<AmicusChat[]>(this._getAmicusChatMessagesURL).pipe(
            tap((response: AmicusChat[]) =>
            {
                // console.log("ChaService #5 - All the chats from getChats() are :: "+JSON.stringify(response))
                this._chats.next(response);
            }),
        );
    }

    /**
     * Get user specific chats
     * @param user_id
     * @param contact_id
     */
    getUserChats(user_id: string, contact_id: string): Observable<any>
    {
        return this._httpClient.get<AmicusChat[]>(this._getAmicusChatUserMessagesURL+'?user_id='+user_id+'&contact_id='+contact_id).pipe(
            tap((response: AmicusChat[]) =>
            {
                // console.log("3. PPPPPPPPPPPPPPP defaultUserChatsResolver - ChaService #6 - The user ("+user_id+") specific chats are :: "+JSON.stringify(response))
                this._chats.next(response);
            }),
        );
    }

    /**
     * Get user specific chats by conversation id and user id
     * @param user_id
     * @param conversation_id
     */
    getUserChatsByConversationId(contact_id: string,conversation_id: string): Observable<any>
    {
        return this._httpClient.get<AmicusChat[]>(this._getAmicusChatUserMessagesByConversationURL+'?conversation_id='+conversation_id+'&contact_id='+contact_id).pipe(
            tap((response: AmicusChat[]) =>
            {
                // console.log("ChaService #7 - The user ("+contact_id+") for conversation ("+conversation_id+") specific chats are :: "+JSON.stringify(response))
                this._chats.next(response);
            }),
        );
    }


    /**
     * Get conversation by conversation id
     *
     * @param id
     */
    getConversationByConversationId(id: string): Observable<any>
    {
        return this._httpClient.get<AmicusConversation>(this._getAmicusChatConversationsByConversationIdURL+'?conversation_id='+id).pipe(
            map((conversation) =>
            {
                // Update the chat
                this._conversation.next(conversation);

                // Return the chat
                return conversation;
            }),
            switchMap((conversation) =>
            {
                if ( !conversation )
                {
                    console.log("No conversation found with id : "+id)
                    return throwError('Could not find conversation with id of ' + id + '!');
                }

                return of(conversation);
            }),
        );
    }

    /**
     * Create New Chat Conversation
     */
    createNewChatConversation(chatConversation:{
        "lastMessage":string,
        "user":string,
        "contact":string
    }): Observable<AmicusConversation>
    {
        return this._httpClient.post(this._createNewChatConversationURL, chatConversation, httpOptions);
    }

    /**
     * Get chat
     *
     * @param id
     */
    getChatById(id: string): Observable<any>
    {
        return this._httpClient.get<AmicusChat>(this._getAmicusChatMessagesByUserIdURL+'?user_id='+id).pipe(
            map((chat) =>
            {
                // console.log("1. Get the selected chat by user id :: "+JSON.stringify(chat))
                // Update the chat
                this._chat.next(chat);

                // Return the chat
                return chat;
            }),
            switchMap((chat) =>
            {
                if ( !chat )
                {
                    console.log("No chat found with id : "+id)
                    return throwError('Could not found chat with id of ' + id + '!');
                }

                return of(chat);
            }),
        );
    }

    /**
     * Get chat by cha id
     *
     * @param id
     */
    getChatByConversationId(cid: string): Observable<any>
    {
        return this._httpClient.get<AmicusChat>(this._getAmicusChatMessagesByConversationIdURL+'?conversation_id='+cid).pipe(
            map((chat) =>
            {
                // console.log("3. Observable Material getChatByConversationId")
                // console.log("2. Get the selected chat by conversation ID :: "+JSON.stringify(chat))
                // Update the chat
                this._chat.next(chat);

                // Return the chat
                return chat;
            }),
            switchMap((chat) =>
            {
                if ( !chat )
                {
                    console.log("No chat found with id : "+cid)
                    return throwError('Could not found chat with id of ' + cid + '!');
                }

                return of(chat);
            }),
        );
    }

    /**
     * Get chat by cha id
     *
     * @param id
     */
    getChatByUserIdAndConversationId(cid: string,uid: string): Observable<any>
    {

        // console.log("The conversation to search is :: "+cid+" and he User id is :: "+uid)

        return this._httpClient.get<AmicusChat>(this._getAmicusChatMessagesByConversationIdAndUserIdURL+'?conversation_id='+cid+'&user_id='+uid).pipe(
            map((chat) =>
            {

                // console.log("3. Get the selected chat by User ID and Conversation ID :: "+JSON.stringify(chat))
                // Update the chat
                this._chat.next(chat);

                // console.log("The chat messages for user "+uid+" and conversation "+cid+" are :: "+JSON.stringify(chat))

                // Return the chat
                return chat;
            }),
            switchMap((chat) =>
            {
                if ( !chat )
                {
                    console.log("No chat found with id : "+cid)
                    return throwError('Could not found chat with id of ' + cid + '!');
                }

                return of(chat);
            }),
        );
    }

    /**
     * Get chat by cha id
     *
     * @param id
     */
    getChatByUserIdAndConversationUId(cid: string,uid: string): Observable<any>
    {

        return this._httpClient.get<AmicusChat>(this._getAmicusChatConversationsByConversationIdURL+'?conversation_id='+cid).pipe(
            map((chat) =>
            {

                // console.log("4. Get the selected chat by user id conversation UUID :: "+JSON.stringify(chat))

                // Update the chat
                this._chat.next(chat);

                // console.log("The chat messages for user "+uid+" and conversation "+cid+" are :: "+JSON.stringify(chat))

                // Return the chat
                return chat;
            }),
            switchMap((chat) =>
            {
                if ( !chat )
                {
                    console.log("No chat found with id : "+cid)
                    return throwError('Could not found chat with id of ' + cid + '!');
                }

                return of(chat);
            }),
        );
    }

    // /**
    //  * Save new legal request
    //  * @param chatMessage
    //  */
    // saveNewChatMessage(chatMessage: {
    //     chat_conversation_id: string;
    //     contact_id: string;
    //     is_mine: boolean;
    //     chat_message: string;
    // }): Observable<any>
    // {

    //     return this._httpClient.post(this._saveNewChatMessageURL, chatMessage, httpOptions);

    // }

    /**
     * Update chat
     *
     * @param id
     * @param chat
     */
    updateChat(id: string, chat: Chat): Observable<Chat>
    {
        return this.chats$.pipe(
            take(1),
            switchMap(chats => this._httpClient.patch<Chat>('api/apps/chat/chat', {
                id,
                chat,
            }).pipe(
                map((updatedChat) =>
                {
                    // Find the index of the updated chat
                    const index = chats.findIndex(item => item.id === id);

                    // Update the chat
                    chats[index] = updatedChat;

                    // Update the chats
                    this._chats.next(chats);

                    // Return the updated contact
                    return updatedChat;
                }),
                switchMap(updatedChat => this.chat$.pipe(
                    take(1),
                    filter(item => item && item.id === id),
                    tap(() =>
                    {
                        // Update the chat if it's selected
                        this._chat.next(updatedChat);

                        // Return the updated chat
                        return updatedChat;
                    }),
                )),
            )),
        );
    }

    /**
     * Get the user's default conversation from the database
     * @param catalog_id
     * @param user_id
     */
    getUserDefaultChatsByCatalogAndUserId(user_id: string,catalog_id: string): Observable<any>
    {
        //return this._httpClient.post(this._meURL, email, httpOptions);
        return this._httpClient.post(this._defaultUserChatMessagesURL, {
            "user_id":user_id,
            "catalog_id":catalog_id,
        }, httpOptions).pipe(

            catchError(() =>

                // Return false
                of(false),
            ),
            switchMap((response: any) =>
            {
                // console.log("1. PPPPPPPPPPPPPPP ::: The details in response to getUserDefaultChatsByCatalogAndUserId is : "+JSON.stringify(response))

                // Store the user on the user service
                //this._userService.user = response; //cloneDeep(this._user); //response.user;

                // Return a new observable with the response
                return of(response);

            })

        )
    }

    /**
     * Reset the selected chat
     */
    resetChat(): void
    {
        this._chat.next(null);
    }

    /**
     * Get all feedback categories
     */
    getActiveFeedbackCategories(): Observable<FeedbackCategory[]>
    {

        return this._httpClient.get<FeedbackCategory[]>(this._getFeedbackCategoriesURL);

    }

    /**
     * Get feedback subcategories by category ID
     * @param categoryId
     * @param sentiment
     */
    getFeedbackSubcategoriesByCategoryID(categoryId: number,sentiment: string): Observable<FeedbackSubcategory[]>
    {

        return this._httpClient.get<FeedbackSubcategory[]>(this._getFeedbackSubcategoriesByDomainIdURL+'?category_id='+categoryId+'&sentiment='+sentiment);

    }

    /**
     * Update the chat message with the provided user feedback
     *
     * @param feedbackMessageObject
     */
    updateChatMessageWithFeedback(feedbackMessageObject: FeedbackMessageObject): Observable<any>
    {
        return this._httpClient.post(this._updateChatMessageWithFeedbackURL, feedbackMessageObject, httpOptions);
        // return this._httpClient.patch<FeedbackMessageObject>(this._updateChatMessageWithFeedbackURL, {feedbackMessageObject}).pipe(
        //     map((response) =>
        //     {
        //         this._user.next(response);
        //     }),
        // );
    }

    /**
     * Send a payment request to the server
     * @param tokenPurchaseRequest
     */
    mpesaTokenPurchaseRequest(tokenPurchaseRequest: {
        user_id: string;
        phone: string;
        amount: string;
        number_of_tokens: number;
        transaction_description: string;
        account_number: string;
    }): Observable<any>
    {

        return this._httpClient.post(this._mpesaTokenPurchaseRequestURL, tokenPurchaseRequest, httpOptions);

    }

    /**
     * Send a payment request to the server
     * @param tokenDonationRequest
     */
    mpesaTokenDonationRequest(tokenDonationRequest: {
        user_id: string;
        phone: string;
        amount: string;
        number_of_tokens: number;
        transaction_description: string;
        account_number: string;
        beneficiary_id: string;
    }): Observable<any>
    {

        return this._httpClient.post(this._mpesaTokenDonationRequestURL, tokenDonationRequest, httpOptions);

    }

    // /**
    //  * Get chatbot contacts
    //  */
    getChatbotTableOfContentsByChatbotContactID(chatbotContactId: number): Observable<ChatbotableOfContentsWithTitles[]>
    {

        return this._httpClient.get<ChatbotableOfContentsWithTitles[]>(this._getAmicusChatbotTableOfContentsByChatbotContactIDURL+'?chatbot_contact_id='+chatbotContactId);

    }

}
